import React, { useState } from 'react'
import { Col, Container, Accordion, Form, Row, Spinner } from 'react-bootstrap'
import { customStyles, services } from '../../constants'

const Services = () => {

  const [formData, setFormData] = useState({ filter: false, existing: false, maintenance: false })

  const renderServices = () => {
    let filteredServices = [...services];

    if (formData.filter) {
      filteredServices = filteredServices.filter( service => {
        return (service.existing === formData.existing || service.existing === undefined) && 
        (service.maintain === formData.maintenance || service.maintain === undefined)
      })
    }

    return filteredServices.map(service => <Accordion.Item 
      key={service.id} 
      eventKey={service.id} 
      className='bg-warning color-warning'>
      <Accordion.Header >{service.name}</Accordion.Header>
      <Accordion.Body >
        {service.description}
        <br></br>
        <br></br>
        {service.features}
      </Accordion.Body>
    </Accordion.Item>)
  }
  return (
    <div>
      { customStyles }
      <Container className='mb-3 mt-3'>
        <Row>
          <h1 className='mt-3 mb-3 border-bottom'>Bookkeeping and Accounting Services</h1>
        </Row>
        <Form className='mt-4 mb-4 p-2 ps-4 border-bottom'>
          <Row>
            <h4>Use the buttons below to see which services match your business!</h4>
          </Row>
          <Row>
            <Form.Group className='mt-3 mb-3 fs-5'>
              <Form.Label>Have you been keeping financial records for your business?</Form.Label>
              <Form.Switch 
                label={formData.existing ? "Yes, I have been keeping financial records." : "No, I have not been keeping financial records."}
                checked={formData.existing}
                onChange={() => setFormData({...formData, existing: !formData.existing})}
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group className='mt-3 mb-3 fs-5'>
              <Form.Label>Would you like Apis to do your bookkeeping for you?</Form.Label>
              <Form.Switch 
                label={formData.maintenance ? "Yes, I would like to hire Apis to maintain my financial records." : "No, I would like to learn how to maintain my financial records."}
                checked={formData.maintenance}
                onChange={() => setFormData({...formData, maintenance: !formData.maintenance})}
              />
            </Form.Group>
          </Row>
          <Row className='mt-3 d-flex'>
            <Col xs={12}>
              <div className='d-flex'>
                <span className='me-3 fs-5 text-uppercase'>Check here to see which services match your needs:</span>
                <Form.Check 
                  type='checkbox'
                  className='fs-5'
                  onClick={() => setFormData({...formData, filter: !formData.filter})}
                />
              </div></Col>
          </Row>
        </Form>
        <Row className='mb-4 pb-4'>
          <Accordion defaultActiveKey='1' flush>
            { renderServices() }
          </Accordion>
        </Row>
      </Container>
    </div>
  )
}

export default Services
