import React from 'react'
import { Container, Row, Col} from 'react-bootstrap'
import { InlineWidget } from 'react-calendly'

const Contact = () => {
  return (
    <Container className='mb-3 mt-3'>
      <Row className='mb-4'>
        <h1 className='mt-3 border-bottom'>Contact Us</h1>
      </Row>
      <Row>
        <Col xs='3'>
          <h3>Phone: </h3>
        </Col>
        <Col className='text-align-start'>
          <p>(303) 945 - 2104</p>
        </Col>
      </Row>
      <Row>
        <Col xs='3'>
          <h3>Address: </h3>
        </Col>
        <Col className='text-align-start'>
          <p>2000 S. Corona Street</p>
          <p>Denver, CO 80210</p>
        </Col>
      </Row>
      <Row>
        <Col xs='3'>
          <h3>Email: </h3>
        </Col>
        <Col className='text-align-start'>
          <p>sschutz@apisbusinesssolutions.com</p>
        </Col>
      </Row>
      <Row className='mt-3'>
        <InlineWidget url="https://calendly.com/apis-business-solutions" />
      </Row>
    </Container>
  )
}

export default Contact
