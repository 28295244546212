import React from 'react'
import { Container, Row, Col, Image } from "react-bootstrap"
import { InlineWidget } from 'react-calendly'

const Home = () => {
  return (
    <Container className='mt-1 p-auto'>
      <Row className='pt-4'>
        <Col className='lg-12 py-auto px-auto home-intro'>
          <h1 className='text-center pb-3 mt-3'>WE TAKE THE STING OUT OF BOOKKEEPING</h1>
          {/* <div className='bee'>
            <Image fluid={true} src='bee.png'></Image>
          </div> */}
          <p className='text-start fs-4'>
            For over 20 years, we've been helping small business owners get the most out of their financial records. 
          </p>
          <div className='fs-4 ms-3 mb-3'>
            <div className='list-item'>
              <span className='text-warning hex'>&#x2B22;</span>
              <span>
                Do you feel overwhelmed by bookkeeping?
              </span>
            </div>
            <br></br>
            <div className='list-item'>
              <span className='hex text-warning'>&#x2B22;</span>
              <span>
                Are you worried your records aren't organized correctly?
              </span>
            </div>
            <br></br>
            <div className='list-item'>
              <span className='hex text-warning'>&#x2B22;</span>
              <span>
                Have you been avoiding setting up your 'books' because you don't know where to start?
              </span>
            </div>
            <br></br>
            <div className='list-item'>
              <span className='hex text-warning'>&#x2B22;</span>
              <span>
                Do you want reports that help you make decisions about your business?
              </span>
            </div>
          </div>
          <p className='fs-4'>We can help you rest assured that your financial records are an asset to your business, not a liability.</p>
        </Col>
      </Row>
      <Row className='mt-4 pt-4 border-rounded'>
        <Col className=''>
          <h1 className='pt-4 fw-bolder text-center'>SCHEDULE A FREE CONSULTATION</h1>
          <br></br>
        </Col>
      </Row>
      <Row>
        <InlineWidget url="https://calendly.com/apis-business-solutions" />
      </Row>
    </Container>
  )
}

export default Home
