import './App.css';
import { Routes, Route } from "react-router-dom";
import Services from './features/services/Services';
import Bios from './features/bios/Bios';
import Home from './features/home/Home';
import Navigation from './features/navigation/Navigation';
import Contact from './features/contact/Contact';

function App() {
  return (
    <div>
      <Navigation />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/our-services' element={<Services />} />
        <Route path='/bios' element={<Bios />}/>
        <Route path='/contact' element={<Contact />}/>
      </Routes>    
    </div>
  );
}

export default App;
