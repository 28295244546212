import React from 'react'
import { Container, Card, Col, Row } from 'react-bootstrap'
import { bios } from '../../constants'

const renderBios = () => {
  return bios.map((bio, index) => 
    <Col key={index}>
      <Card className='m-2'>
        <Card.Img variant="top" src={bio.image} />
        <Card.Header className='fs-2'>{bio.name}</Card.Header>
        <Card.Title className='ps-3 pt-3 pe-3 fs-4'>{bio.title}</Card.Title>
        <Card.Body>
          { bio.description.map((p, i) => <Card.Text key={i} className='fs-5'>{p}</Card.Text>) }   
        </Card.Body>
      </Card>
    </Col>
  )
}
const Bios = () => {
  return (
    <Container className='mb-3 mt-3'>
      <Row className="mb-4">
        <h1 className='mt-3 border-bottom'>Meet the Hive</h1>
      </Row>
      <Row xs={1} md={2} className="mt-3">{renderBios()}</Row>
    </Container>
  )
}

export default Bios
