import React from 'react'
import { Nav, Container, Navbar } from 'react-bootstrap'
import Consultation from '../consultation/Consultation'
//format buttons with black text

const Navigation = () => {
  const links = <>
    <Nav.Link className='fs-4' href="/">Home</Nav.Link>
    <Nav.Link className='fs-4' href="/our-services">Services</Nav.Link>
    <Nav.Link className='fs-4' href="/bios">About</Nav.Link>
    <Nav.Link className='fs-4 me-3' href="/contact">Contact</Nav.Link>
    <Consultation className='fs-4' variant='dark-outline'></Consultation>
  </>
  return (
    <div>
      <style type="text/css">
        {`
        .btn-dark-outline {
          background-color: #ffc107;
          color: #000000E6;
          width: fit-content;
          border-style: solid;
          border-color: #000000E6;
        }

        .btn-dark-outline:hover {
          background-color: #000000E6;
          border-style: solid;
          border-color: #ffc107;
          color: #ffc107
        }

        .btn-yellow-outline {
          color: #f3b700;
          border-style: solid;
          border-color: #112B80;
          width: fit-content;
        }
        `}
      </style>
      <Navbar className='nav' variant='light' bg='warning' expand="lg" sticky="top">
        <Container>
          <Navbar.Brand className='fs-2' href="/">
            <img
              src="/apisLogoBlack.png"
              width="250"
              height="250"
              className="d-inline-block align-center ms-5 me-2"
              alt="Apis logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              { links }
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  )
}

export default Navigation
