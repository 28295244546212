export const services = [
  {
    id: 1,
    name: "Free Consultation", 
    description: "Free consultation where we gather information about you and your business. We want to hear your story.", 
    features: "Relationships are important to us. We use this time to get to know each other and decide together if we want to build a business relationship based on your needs and the services we provide.\n You’ll leave this meeting with an estimate for the review of your current records and the confidence that we are the right fit for you and your company’s goals."
  }, 
  {
    id: 2,
    name: "Review of Current Records", 
    description: "We take a deep dive into your company to assess your current bookkeeping needs and processes.  We create an action plan together to ensure you have the financial information you need at your fingertips.", 
    features: "We know that having a good plan is the first step to reaching your goals.  We help you understand the state of your current business and create a step-by-step plan for getting your bookkeeping accurate, current  and manageable.", 
    existing: true
  }, 
  {
    id: 3,
    name: "Set Up Books", 
    description: "We set up your accounting software to match your needs, industry and business model.", 
    features: "We have the accounting knowledge and business experience to set up your accounting system to track your financial transactions in a way that empowers you to make informed financial decisions that support your goals.", 
    existing: false
  },
  {
    id: 4,
    name: "Customize Existing Books", 
    description: "We modify your existing accounting software to match your needs, industry and business model.", 
    features: "We have the accounting knowledge and business experience to ensure your accounting system tracks your financial transactions in a way that empowers you to make informed financial decisions that support your goals.", existing: true
  },
  {
    id: 5,
    name: "Clean Up Existing Books", 
    description: 'We organize your existing accounting records to match the accounting system we put in place in the previous phase.', 
    features: "We utilize our experience and tools to reconfigure your existing accounting data to reflect your current financial state so you can be confident moving forward.", 
    existing: true
  },
  {
    id: 6,
    name: "Catch Up Bookkeeping", 
    description: "We enter any missing accounting records to match the accounting system we put in place.", 
    features: "Now, you will be working from an accurate starting point! From now on you’ll have the peace of mind to focus on your business goals.", 
    existing: true
  },
  {
    id: 7,
    name: "Maintenance", 
    description: "We understand accounting so you don’t have to. Our remote bookkeeping is tailored to your needs.  Let us do all the work or just take on the tasks you don’t have time for.", 
    features: "Now, you can work on your business, instead of in your business, knowing your financial reports are current and accurate.", 
    maintain: true
  },
  {
    id: 8,
    name: "Bookkeeping Training" , 
    description: "We train you how to stay on the right track and check in to make sure your records are accurate. You do the work you can take on and we take care of the rest.", 
    features: "You’ll have a more detailed perspective of your business. You’ll know your financial reports are current and accurate, and gain insight into the opportunities they hold.", 
    maintain: false
  }
];

export const bios = [
  {
    image: 'Suzy.jpg',
    name: 'Suzy Schutz',
    title: 'Co-Founder and CEO',
    description: [
      "Like many of our entrepreneurial clients, Suzy didn't achieve her expertise through formal education. The expertise she's built in her 30+ year bookkeeping and accounting career was gained through on-the-job service, self-study and mentoring from peers in her field. In 1997 she founded her first private accounting and bookkeeping practice. Based on that experience she partnered with Nancy Barnett to form Apis Business Solutions, in August 2008.", "In 2014, she and Nancy formed eL3 where she is the Finance Curriculum Director. Suzy designs and teaches financial business classes through the Colorado network of Small Business Development Centers and the Offices of Economic Development. She is also a mentor for the Champions program at the Commons on Champa which is a nonprofit resource for the Denver Offices of Economic Development. She comes from a long line of entrepreneurs and educators and embraces their combined spirits of self-reliance and courage backed by the knowledge needed to maintain success.", "Suzy focuses her services on small to medium sized businesss with a passion for providing useful and accurate financial information in a way that matches the needs and style of the individual business owner."
    ]
  },
  {
    image: 'nancy.jpg',
    name: 'Nancy Barnett',
    title: 'Co-Founder and Business Consultant',
    description: [
      "Nancy’s passion is helping business owners start or improve their businesses by understanding all the components needed to build a strong business foundation. She takes great pleasure in helping owners develop their business plans and think strategically.",
      "In 2008, she joined Suzy Schutz to create Apis Business Solutions. Nancy and Suzy provided training for the Colorado Department of Vocational Rehab’s entrepreneurial program. Nancy has been both a teacher and consultant at the Colorado network Small Business Development Center where she teaches classes including business plan development and business startup basics for start-ups and established businesses.", "Her work with the SBDC lead to the business planning curriculum for our second company, eL3. Prior to co-founding eL3, Nancy spent 20+ years in the corporate arena including education director of a large business college in California as well as 14 years with AAA Colorado as Director of Corporate Training. During her tenure at AAA she created award-winning training programs and instituted a successful Quality Control Program for the Emergency Road Service Center."
    ]
  },
  {
    image: 'Colleen.jpg',
    name: 'Colleen Kennedy',
    title: 'Director of Operations and Bookkeeper',
    description: [
      "Colleen joined the team in 2017, working for both eL3 and Apis Business Solutions. Colleen enjoys training small business owners how to utilize and manage their accounting software effectively. She ensures the operations of both companies are functioning smoothly and efficiently.",
      "Her background in the service industry has been instrumental in enacting the necessary systems to support these expanding companies. Working in both corporate and franchise restaurant models for 8+ years progressing from Host to Server to General Manager strengthened her understanding of the importance of systems and efficiencies. Her experience enables Nancy and Suzy to focus more on the business rather than in the business."
    ]
  },
  {
    image: '',
    name: 'Mary McDowell',
    title: 'Bookkeeper',
    description: [
      "Mary understands small businesses because she comes from an entrepreneurial family. She learned from her father about the importance of financial management and has endeavored to bring good financial management to other small business owners. She has over 7 years of accounting and bookkeeping experience.",
      "Her previous industry experience includes, but is not limited to: Construction, Real Estate, Property Management, Marketing & Printing Services, Medical Facilities, Education, Software Sales and County Offices. Mary completed her B.A. in Business Administration at Azusa Pacific University."
    ]
  }
]

export const customStyles = <style type="text/css">
  {`
  .btn-dark-outline {
    background-color: #ffc107;
    color: #000000E6;
    width: fit-content;
    border-style: solid;
    border-color: #000000E6;
  }

  .btn-dark-outline:hover {
    background-color: #000000E6;
    border-style: solid;
    border-color: #ffc107;
    color: #ffc107
  }

  .btn-yellow-outline {
    color: #f3b700;
    border-style: solid;
    border-color: #112B80;
    width: fit-content;
  }
  .btn-yellow-outline:expanded {
    color: #f3b700;
    border-style: solid;
    border-color: #112B80;
    width: fit-content;
  }
  `}
</style>