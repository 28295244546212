import React from 'react'
import { PopupModal } from "react-calendly";
import { Row, Button } from 'react-bootstrap';
import { useState } from 'react';
import { customStyles } from '../../constants';

const Consultation = ({ pageSettings, utm, prefill }) => {

  const [isOpen, setIsOpen] = useState(false)

  return (
    <div>
      { customStyles }
      <Row>
        <Button
          className='fs-4 ms-2 mb-2' 
          variant='dark-outline'
          onClick={() => setIsOpen(true)}
        >
          Free Consultation
        </Button>
        <PopupModal
          url="https://calendly.com/apis-business-solutions"
          pageSettings={pageSettings}
          utm={utm}
          prefill={prefill}
          onModalClose={() => setIsOpen(false)}
          open={isOpen}
          /*
          * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
          * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
          */
          rootElement={document.getElementById("root")}
        />
      </Row>
    </div>
  )
}

export default Consultation
